import {Controller} from "@hotwired/stimulus"
import Rails from '@rails/ujs'
import {Turbo} from "@hotwired/turbo-rails";


export default class extends Controller {
    static targets = ['value', 'state', 'revision']
    static values = {type: String, attribute: String, unlocked: Boolean, url:String}

    connect() {
        console.log('SurveyQuestion#connected')
        this.attribute = this.attributeValue
        this.suggest = this.data.get('suggest')
    }

    changing(event) {
        if (event.keyCode != 9) {
            this.stateTarget.innerHTML = '<i class="fa-regular fa-save text-danger fa-fw"></i>'
        }
    }

    saveChangeTurbo(event) {
        let that = this

        let data = new FormData()

        data.append(this.valueTarget.name, this.valueTarget.value)

        fetch(this.urlValue, {
            method: 'PATCH',
            body: data,
            headers: {
                Accept: "text/vnd.turbo-stream.html",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.content,
            }
        })
            .then(r => r.text())
            .then(html => Turbo.renderStreamMessage(html))

    }

}